// import page from './app.vue'

// import './style/fullpage.css'
// import VueFullPage from 'vue-fullpage.js'

import { createApp } from 'vue'
import App from './App.vue'
import routers from './routers/index.js'

// import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSplide from '@splidejs/vue-splide';

const app = createApp(App)
app.use(routers)
app.use(VueSplide)
app.mount('#app')
