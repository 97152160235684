import { createRouter, createWebHistory } from "vue-router";

const routes = [
   {
      path: "/",
      name: "main",
      component: () => import('@/index.vue') // index
   },
   {
      path: "/port",
      name: "portfolio",
      component: () => import('@/port.vue')
   },
   {
      path: "/port2",
      name: "portfolio2",
      component: () => import('@/port2.vue')
   }
];

const router = createRouter({
   history: createWebHistory(),
   routes
});

// router.beforeEach((to, from, next) => {
  // 조건
  // alert('here')
// })

export default router;
